<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col>
          <h1>{{ testimonial.title }}</h1>
        </v-col>
      </v-row>
      <div>
        <v-row>
          <v-col cols="6">
            <v-card>
              <v-toolbar flat dark dense color="accent">
                <v-toolbar-title>Basic Details</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  depressed
                  small
                  @click="$refs.testimonialForm.openForm(testimonial)"
                >
                  <v-icon left>mdi-pencil</v-icon>Edit
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <v-simple-table class="mb-5 table" divider="true">
                  <tbody>
                    <tr>
                      <th>Content</th>
                      <td><div v-html="testimonial.content"></div></td>
                    </tr>
                    <tr v-if="testimonial.customer_id">
                      <th>Customer</th>
                      <td>{{ testimonial.customer.full_name }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="6">
            <v-card outlined class="mb-6">
              <v-toolbar flat dark dense color="accent">
                <v-toolbar-title>Images</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  small
                  depressed
                  color="green lighten-4 green--text"
                  @click="$refs.imagesForm.openForm()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </v-toolbar>

              <v-divider></v-divider>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="3"
                    v-for="image in testimonial.images"
                    v-bind:key="image.id"
                  >
                    <v-card>
                      <v-img
                        :src="image.asset_urls.url"
                        class="white--text align-end"
                        gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
                      >
                      </v-img>
                      <v-card-actions>
                        <v-btn icon color="red" @click="openDelete(image)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                          icon
                          color="blue"
                          @click="$refs.imagesForm.openForm(image)"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <v-dialog scrollable v-model="deleteDialog.open" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Delete Image</v-card-title>
        <v-card-text>Are you sure you want to delete this image?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteDialog.loading"
            @click="saveDelete"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <TestimonialForm ref="testimonialForm" />
    <ImagesForm ref="imagesForm" />
  </div>
</template>

<script>
import TestimonialForm from "./components/TestimonialForm";
import ImagesForm from "./components/ImagesForm";

export default {
  data() {
    return {
      deleteDialog: {
        open: false,
        loading: false,
        image: {},
      },
      breadcrumbs: [
        {
          text: "Lomond Hills Labradoodles",
          disabled: false,
          to: { name: "app-dashboard", params: { id: this.$route.params.id } },
        },
        {
          text: "Testimonials",
          disabled: false,
          exact: true,
          to: {
            name: "module-lhl-testimonials",
            params: { id: this.$route.params.id },
          },
        },
      ],
    };
  },

  components: {
    TestimonialForm,
    ImagesForm,
  },

  computed: {
    testimonial() {
      return this.$store.state.lhl.testimonials["testimonial"];
    },
  },

  methods: {
    openDelete(image) {
      this.deleteDialog.image = image;
      this.deleteDialog.open = true;
    },

    resetDelete() {
      this.deleteDialog.open = false;
      this.deleteDialog.loading = false;
      this.deleteDialog.image = {};
    },

    saveDelete() {
      const appId = this.$route.params.id;
      this.deleteDialog.loading = true;

      this.$store
        .dispatch("lhl/testimonials/deleteImage", {
          appId,
          testimonialId: this.$route.params.testimonialId,
          imageId: this.deleteDialog.image.id,
        })
        .then(() => this.resetDelete())
        .catch(() => (this.loading = false));
    },
  },
};
</script>
